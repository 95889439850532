import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'components/Layout';
import { SEO } from 'components/SEO';
import { Review } from 'components/Review';
import { Breadcrumbs } from 'components/Breadcrumbs';

type Props = {
  readonly data: GatsbyTypes.ReviewsPageQuery;
};

const ReviewsPage = ({ data }: Props) => (
  <Layout>
    <SEO title="Отзывы" />
    <div className="pc-row pb-8">
      <Breadcrumbs />
      <h1 className="text-black text-4xl font-bold leading-snug mb-10">Отзывы</h1>
      <div className="pc-free-items">
        {data.api.reviews.map((r) => (
          <Review key={r.rowId} data={r} className="pc-free-item" />
        ))}
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query ReviewsPage {
    api {
      reviews {
        rowId
        ...Review_data
      }
    }
  }
`;

export default ReviewsPage;
